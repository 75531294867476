













import { Component, Vue } from 'vue-property-decorator';
import BoxPage from '@/themes/v1/components/BoxPage.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';

@Component({
  components: { BoxPage, BoxContent },
})
export default class AuthExpired extends Vue {}
